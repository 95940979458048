export default {
  heading: 'Rae Dunn, san-serif',
  body: 'Montserrat',
  monospace: 'Menlo, monospace',
  display: 'Rae Dunn, san-serif',
  googleFonts: ['Montserrat:100,200,300,400,500,600,700,800,900'],
  customFamilies: ['Rae Dunn', 'Rae Dunn Bold'],
  customUrls: [
    'https://gonation.biz/fonts/rae-dunn/rae-dunn.css',
    'https://gonation.biz/fonts/rae-dunn/rae-dunn.css',
  ],
}
